import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardActions, 
    CardContent, 
    Grid, 
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    InputLabel
} from '@material-ui/core'
import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import * as yup from "yup";
import { useHistory } from 'react-router-dom'
// redux
import { connect } from 'react-redux'
import { uploadTask } from '../../../../store/actions/uploadTask'
import UploadFile from './UploadFile'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    text: {
        color: '#000000',
        fontFamily: 'Montserrat'
    },
    textWhite: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    buttonSmall: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginTop: theme.spacing(1)
    },
    divider: {
        color: '#000000',
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
        chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
        chip: {
        margin: 2,
    },
}))

const UploadTask = props => {
    const classes = useStyles()
    const history = useHistory()
    const { 
        inboxData,
        handleCloseDetailSurat,
        uploadTask,
        uploadTask: {
            loadingUploadTask
        }
    } = props
    const { handleSubmit } = useForm();

    const [ formState, setFormState ] = useState({
        values: {}
    })

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: 
                event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
    };

    const [fileTask, setFileTask] = useState([]);
    const handleChangeFile = event => {
        setFileTask(event[0]);
        // let reader = new FileReader();
        // reader.readAsDataURL(event[0]);
        // reader.onload = function(){
        //     setB64(reader.result);
        // }
    };

    const onSubmit = e => {
        console.log(inboxData.disposable.id, formState.values)
        uploadTask(inboxData.disposable.id, formState.values, fileTask, history)
        handleCloseDetailSurat(inboxData)
    }

    // const bagian = JSON.parse(sessionStorage.getItem('data'))

    return loadingUploadTask ?
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <Card className={classes.root}>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={12}
                    >
                        <TextField
                            fullWidth
                            name="catatan"
                            value={formState.values.catatan || ''}
                            onChange={handleChange}
                            placeholder="Buat catatan"
                            label="Catatan"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Upload File</InputLabel>
                        <UploadFile value={fileTask} handleChangeFile={handleChangeFile} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item>
                        <Button className={classes.button} onClick={handleSubmit(onSubmit)}>
                            <div className={classes.textWhite}>
                                Submit
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    </Fragment>
    
}

const mapStateToProps = state => ({
    uploadTask: state.uploadTask
})

export default connect(mapStateToProps, { uploadTask })(UploadTask)
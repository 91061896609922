import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom'
import {
	Backdrop,
	CircularProgress,
	Grid,
	Link
} from '@material-ui/core'
import moment from 'moment'

// redux
import { connect } from 'react-redux'
import { getHistory } from '../../../store/actions/suratMasuk'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4)
	},
	paper: {
		padding: '6px 16px',
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	fontTitle: {
		fontFamily: 'Montserrat',
		fontWeight: 700
	},
	fontText: {
		fontFamily: 'Montserrat'
	}
}));

const History = props => {
	const classes = useStyles();
	const params = useParams()
	const {
		getHistory,
		suratMasuk: {
			history,
			loadingHistory
		}
	} = props

	useEffect(() => {
		getHistory(params.id)
	}, [ getHistory, params ])

	console.log(history)

  return loadingHistory ? 
	<Backdrop className={classes.backdrop} open>
			<CircularProgress color="inherit" />
	</Backdrop>
	:
	<Fragment>
		<div className={classes.root}>
			<Grid
				container
				spacing={3}
                justify="space-between"
			>
				<Grid
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<Typography variant="h4" className={classes.fontText}>
						History Perjalanan Surat
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
				>
					<Timeline align="alternate">
						{history.map(item => (
							<TimelineItem>
								<TimelineOppositeContent>
									<Typography variant="body2" color="textSecondary">
										{moment(item.updated_at).format('DD MMMM YYYY HH:mm')}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
										<TimelineDot />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={classes.paper}>
										<Typography variant="h6" className={classes.fontTitle} component="h1">
											{item.status}
										</Typography>
										{item.historable.perihal && (
											<Typography className={classes.fontText}>
												Perihal : {item.historable.perihal}
											</Typography>
										)}
										{item.historable.isi_disposisi && (
											<Typography className={classes.fontText}>
												Isi Disposisi : {item.historable.isi_disposisi}
											</Typography>
										)}
										{item.url && (
											<Typography className={classes.fontText}>
												Download Hasil Pekerjaan : <Link href={item.url} target="_blank">Download</Link>
											</Typography>
										)}
									</Paper>
								</TimelineContent>
							</TimelineItem>
						))}
					</Timeline>
				</Grid>
			</Grid>
		</div>
	</Fragment>
}

const mapStateToProps = state => ({
	suratMasuk: state.suratMasuk
})

export default connect(mapStateToProps, { getHistory })(History)
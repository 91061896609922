import * as actions from '../actions/actionTypes'

const initialState = {
    uploadTaskData: null,
    loadingUploadTask: false,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.UPLOAD_TASK_START:
            return {
                ...state,
                loadingUploadTask: true
            }
        case actions.UPLOAD_TASK_SUCCESS:
            return {
                ...state,
                uploadTaskData: payload,
                loadingUploadTask: false
            }
        case actions.UPLOAD_TASK_FAIL:
            return {
                ...state,
                error: payload,
                loadingUploadTask: false
            }
        default:
            return state
    }
}
import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardActions, 
    CardContent, 
    Grid, 
    InputLabel, 
    Typography,
    Button,
    Divider,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core'
import moment from 'moment'

// redux
import { connect } from 'react-redux'
import { getDispositionChoice } from '../../../store/actions/suratMasuk'
import { getBagian } from '../../../store/actions/bagian'
import Disposisi from './Disposisi'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    text: {
        color: '#000000',
        fontFamily: 'Montserrat'
    },
    textWhite: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    buttonSmall: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginTop: theme.spacing(1)
    },
    divider: {
        color: '#000000',
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
}))

const DetailSuratMasuk = props => {
    const classes = useStyles()
    const {
        inboxData,
        getDispositionChoice,
        getBagian,
        handleCloseDetailSurat,
        suratMasuk: {
            loadingDownloadBuktiTerima,
            loadingDownloadDetailSurat,
            loadingDispositionChoiceList,
            dispositionChoiceList
        },
        bagian: {
            listBagian
        }
    } = props

    const [ openDialogDisposisi, setOpenDialogDisposisi ] = useState({
        open: false,
        item: null
    })

    const handleOpenDialogDisposisi = e => {
        setOpenDialogDisposisi({
            open : true,
            item: e
        })
    }

    const handleCloseDialogDisposisi = e => {
        setOpenDialogDisposisi({
            open : false,
            item: e
        })
    }

    useEffect(() => {
        getBagian()
        getDispositionChoice()
    }, [getBagian, getDispositionChoice])

    return loadingDownloadBuktiTerima || loadingDownloadDetailSurat || loadingDispositionChoiceList ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <Card className={classes.root}>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Tgl Terima:</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {moment(inboxData.tanggal_terima).format('DD MMMM yyyy')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Tgl/No Surat:</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {moment(inboxData.tanggal_surat).format('DD MMMM yyyy')} | {inboxData.no_surat}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Perihal:</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {inboxData.perihal}
                        </Typography>
                    </Grid>
                    <Divider variant="middle" className={classes.divider} />
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Lampiran Dokumen:</InputLabel>
                        <Button size="small" className={classes.buttonSmall} target="_blank" href={inboxData.file_path}>
                            <div className={classes.textWhite}>
                                Download
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item>
                        {/* <Button className={classes.button} onClick={() => handleDownloadDetailSurat(inboxData)}>
                            <div className={classes.textWhite}>
                                Download Surat
                            </div>
                        </Button> */}
                    </Grid>
                    {sessionStorage.getItem('role') === 'manager' && (
                        <>
                            {inboxData.status_surat.id === 1 && (
                                <Grid item>
                                    <Button className={classes.button} onClick={() => handleOpenDialogDisposisi(inboxData)}>
                                        <div className={classes.textWhite}>
                                            Disposisi
                                        </div>
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </CardActions>
        </Card>
        <Dialog
            open={openDialogDisposisi.open}
            onClose={() => handleCloseDialogDisposisi(openDialogDisposisi.item)}
        >
            <DialogTitle>
                <Typography>
                Disposisi surat dengan nomor surat ({inboxData.no_surat})
                </Typography>
            </DialogTitle>
            <DialogContent>
            <Disposisi
                inboxData={openDialogDisposisi.item}
                listBagian={listBagian}
                dispositionChoiceList={dispositionChoiceList}
                handleCloseDialogDisposisi={handleCloseDialogDisposisi}
                handleCloseDetailSurat={handleCloseDetailSurat}
            />
            </DialogContent>
        </Dialog>
    </Fragment>
}

const mapStateToProps = state => ({
    suratMasuk: state.suratMasuk,
    bagian: state.bagian
})

export default connect(mapStateToProps, { getBagian, getDispositionChoice })(DetailSuratMasuk)
import * as actions from '../actions/actionTypes'

const initialState = {
    staffMin: null,
    createStaffMin: null,
    detailStaffMin: null,
    editStaffMin: null,
    loadingEditStaffMin: false,
    loadingDetailStaffMin: true,
    loadingCreateStaffMin: false,
    loadingStaffMin: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.GET_STAFF_MIN:
            return {
                ...state,
                staffMin: payload,
                loadingStaffMin: false
            }
        case actions.GET_DETAIL_STAFF_MIN:
            return {
                ...state,
                detailStaffMin: payload,
                loadingDetailStaffMin: false
            }
        case actions.ADD_STAFF_MIN_START:
            return {
                ...state,
                loadingCreateStaffMin: true
            }
        case actions.ADD_STAFF_MIN_SUCCESS:
            return {
                ...state,
                createStaffMin: payload,
                loadingCreateStaffMin: false
            }
        case actions.ADD_STAFF_MIN_FAIL:
            return {
                ...state,
                error: payload,
                loadingCreateStaffMin: false
            }
        case actions.UPDATE_STAFF_MIN_START:
            return {
                ...state,
                loadingEditStaffMin: true
            }
        case actions.UPDATE_STAFF_MIN_SUCCESS:
            return {
                ...state,
                editStaffMin: payload,
                loadingEditStaffMin: false
            }
        case actions.UPDATE_STAFF_MIN_FAIL:
            return {
                ...state,
                error: payload,
                loadingEditStaffMin: false
            }
        default:
            return state
    }
}
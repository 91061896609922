import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Grid, 
  Typography,
} from '@material-ui/core';
// import { Link as RouterLink } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
// import '../../../public/index.css'

import {
  InboxMailOnProcess,
  InboxMailDone,
  OutboxMailOnProcess,
  GrafikInboxMail,
  GrafikOutboxMail,
  OutboxMailDone
} from './components'

import { connect } from 'react-redux'
import { getDashboard } from '../../store/actions/dashboard'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  cardMobile: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  executiveSummary: {
    marginBottom: theme.spacing(2)
  },
  btn: {
    backgroundColor: '#FF9300',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFA938',
      opacity: 1,
    },
  },
  title: {
    fontFamily: 'Montserrat'
  }
}));

// const CustomRouterLink = forwardRef((props, ref) => (
//   <div
//     ref={ref}
//     style={{ flexGrow: 1 }}
//   >
//     <RouterLink {...props} />
//   </div>
// ));

const Dashboard = props => {
  const classes = useStyles();
  const {
    getDashboard,
    dashboard:{
      dashboardList,
      loadingDashboardList
    }
  } = props

  const [selectedDate ] = useState(new Date());

    const submitDefault = moment().subtract(7, 'd').format('YYYY-MM-DD');
    const submitDefaultEndDate = moment({}).format('YYYY-MM-DD');
    const [ startDate, setStartDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {
            view: moment().subtract(7, 'd').format('YYYY-MM-DD')
        }
    });
    const handleStartDate = (date) => {
    const changeDate = moment(date).format('YYYY-MM-DD');
        setStartDate(startDate => ({
            ...startDate,
                submit: {
                    submit: changeDate
            },
                view: {
                    view: date
            }
        }));
    };

    const [ endDate, setEndDate ] = useState({
        submit: {
            submit: submitDefaultEndDate
        },
        view: {selectedDate}
    });
    const handleEndDate = (date) => {
    const all = moment(date).format('YYYY-MM-DD');
        setEndDate(endDate => ({
            ...endDate,
            submit: {
                submit: all
            },
            view: {
                view: date
            }
        }));
    };

  useEffect(() => {
    getDashboard(startDate.submit.submit, endDate.submit.submit)
  }, [getDashboard, startDate, endDate])

  console.log(dashboardList)

  return (
      <div className={classes.root}>
        <div className={classes.bgColor}></div>
        <Grid
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>  
            <Typography variant="h4" className={classes.title}>Dashboard</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
            {!loadingDashboardList ? (
              <InboxMailOnProcess inboxMailOnProcess={dashboardList.surat_masuk_on_process} />
            ):(
              <Skeleton variant="rect"></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
              {!loadingDashboardList ? (
                <InboxMailDone inboxMailDone={dashboardList.surat_masuk_done} />
              ):(
                <Skeleton variant="rect"></Skeleton>
              )}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
              {!loadingDashboardList ? (
                <OutboxMailOnProcess outboxMailOnProcess={dashboardList.surat_keluar_on_process} />
              ):(
                <Skeleton variant="rect"></Skeleton>
              )}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
              {!loadingDashboardList ? (
                <OutboxMailDone outboxMailDone={dashboardList.surat_keluar_done} />
              ):(
                <Skeleton variant="rect"></Skeleton>
              )}
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-between">
            <Grid 
                item
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker 
                        fullWidth
                        label="Tanggal Awal"
                        variant="outlined"
                        name="start_date"
                        format="dd MMMM yyyy"
                        value={startDate.view.view} 
                        onChange={handleStartDate} 
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid 
                item
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker 
                        fullWidth
                        label="Tanggal Akhir"
                        variant="outlined"
                        name="end_date"
                        format="dd MMMM yyyy"
                        value={endDate.view.view} 
                        onChange={handleEndDate} 
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            {!loadingDashboardList ? (
              <GrafikInboxMail grafikInboxMail={dashboardList.grafik_surat_masuk} />
            ):(
              <Skeleton variant="rect"></Skeleton>
            )}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            {!loadingDashboardList ? (
              <GrafikOutboxMail grafikOutboxMail={dashboardList.grafik_surat_keluar} />
            ):(
              <Skeleton variant="rect"></Skeleton>
            )}
          </Grid>
        </Grid>
      </div>
  );
};

const mapStateToProps = state => ({
  dashboard : state.dashboard
})

export default connect(mapStateToProps, { getDashboard })(Dashboard)

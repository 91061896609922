import React, { forwardRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Button, DialogContent, Dialog } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import TableSuratMasuk from './TableSuratMasuk'
import EditSuratMasuk from './EditSuratMasuk'

// redux
import { connect } from 'react-redux'
import { getInboxMail } from '../../store/actions/suratMasuk'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Montserrat',
        color: '#000000'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    textButton: {
        fontFamily: 'Montserrat',
        color: '#FFFFFF'
    }
}))

const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
))

const SuratMasuk = props => {
    const classes = useStyles()
    const {
        getInboxMail,
        suratMasuk: {
            inboxMail,
            loadingInboxMail
        }
    } = props

    const idBagian = JSON.parse(sessionStorage.getItem('data'))

    const [ openDialogEdit, setOpenDialogEdit ] = useState({
        open: false,
        object: null
    })
    
    const handleOpenDialogEdit = e => {
        setOpenDialogEdit({
            open: true,
            object : e
        })
    }

    const handleCloseDialogEdit = e => {
        setOpenDialogEdit({
            open: false,
            object : e
        })
    }

    const [ keyword, setKeyword ] = useState('')
    const [page, setPage] = useState(0);

    const handleChangeSearch = event => {
        setKeyword(event.target.value)
        setPage(0)
    }

    useEffect(() => {
        getInboxMail(keyword)
    }, [getInboxMail, keyword])

    return(
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
                justify="space-between"
            >
                <Grid item>  
                    <Typography variant="h4" className={classes.title}>Surat Masuk</Typography>
                </Grid>
                <Grid item>  
                    {idBagian.bagian.seq !== 2 && idBagian.bagian.seq !== 1 && (
                        <Button className={classes.button} component={CustomRouterLink} to='/surat-masuk/form'>
                            <div className={classes.textButton}>
                                + Buat Surat Masuk
                            </div>
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
            >
                <Grid item lg={12}>
                    {!loadingInboxMail && inboxMail !== null ? (
                        <TableSuratMasuk 
                            inboxMail={inboxMail} 
                            handleOpenDialogEdit={handleOpenDialogEdit} 
                            setKeyword={setKeyword}
                            page={page}
                            setPage={setPage}
                            handleChangeSearch={handleChangeSearch}
                        />
                    ):(
                        <Skeleton variant="rect" height={300}></Skeleton>
                    )}
                </Grid>
            </Grid>
            <Dialog
                open={openDialogEdit.open}
                onClose={() => handleCloseDialogEdit(openDialogEdit.object)}
            > 
                <DialogContent>
                    <EditSuratMasuk
                        openDialogEdit={openDialogEdit.object} 
                        getInboxMail={getInboxMail} 
                        handleCloseDialogEdit={handleCloseDialogEdit}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = state => ({
    suratMasuk : state.suratMasuk
})

export default connect(mapStateToProps, { getInboxMail })(SuratMasuk)
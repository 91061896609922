import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'

export const getDashboard = (startDate, endDate) => async dispatch => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}api/dashboard`
  const myData = {
      start_date: startDate,
      end_date: endDate
  }

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            data: myData,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_DASHBOARD,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_DASHBOARD,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
    
}
import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardContent, 
    TextField, 
    Grid, 
    Typography, 
    InputLabel, 
    CardHeader, 
    Button,
    Backdrop,
    CircularProgress,
    MenuItem
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment'
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import {Link} from 'react-router-dom';
import { Info as InfoIcon } from '@material-ui/icons'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useHistory } from 'react-router-dom'

import UploadFile from './UploadFile'
import { connect } from 'react-redux'
import { updateOutboxMail } from '../../../store/actions/suratKeluar'

import '../../../App.css'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    breadcumbs: {
        padding: theme.spacing(4)
    },
    text: {
        fontFamily: 'Montserrat',
        color: '#000000'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
}))

const InboxMailSchema = yup.object().shape({
	no_surat: yup.string().required("Nomor Surat harus diisi"),
	tujuan_surat: yup.string().required("Tujuan Surat harus diisi"),
	pengolah: yup.string().required("Pengolah harus diisi"),
	perihal: yup.string().required("Perihal harus diisi"),
	// keterangan: yup.string().required("Keterangan harus diisi"),
});

const EditSuratMasuk = props => {
    const classes = useStyles()
    const {
        updateOutboxMail,
        suratMasuk: {
            loadingUpdateOutboxMail
        },
        openDialogEdit,
        handleCloseDialogEdit
    } = props
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(InboxMailSchema)
    });

    const [ formState, setFormState ] = useState({
        values: {
            no_surat: openDialogEdit.no_surat,
            tujuan_surat: openDialogEdit.tujuan_surat,
            pengolah: openDialogEdit.pengolah,
            perihal: openDialogEdit.perihal,
            keterangan : openDialogEdit.keterangan,
        }
    })

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: 
                event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
    };

    const mailDateDefault = openDialogEdit.tanggal_surat
    const mailDateDefaultView = new Date(mailDateDefault)
	const [ mailDate, setMailDate ] = useState({
        submit: mailDateDefault,
        view: mailDateDefaultView
        
	});
    const handleMailDate = (date) => {
        const changeDate = moment(date).format('YYYY/MM/DD');
        setMailDate(mailDate => ({
            ...mailDate,
                submit: changeDate,
                view: date
        }));
    };

    const [banner, setBanner] = useState([]);
    const handleChangeFile = event => {
        setBanner(event[0]);
        // let reader = new FileReader();
        // reader.readAsDataURL(event[0]);
        // reader.onload = function(){
        //     setB64(reader.result);
        // }
    };
    
        
    const onSubmit = (event) => {
        console.log(formState.values, banner, mailDate.submit);
        updateOutboxMail(formState.values, banner, mailDate.submit, history, openDialogEdit.id)
        handleCloseDialogEdit(openDialogEdit)
	}
    
    return loadingUpdateOutboxMail ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
            >
                <Grid item>  
                    <Typography variant="h4" className={classes.text}>Form Edit Surat Keluar</Typography>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item lg={12}>
                        <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid 
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <TextField 
                                                fullWidth
                                                label="Nomor Surat"
                                                className={classes.textInput}
                                                name="no_surat"
                                                defaultValue={formState.values.no_surat || ''}
                                                onChange={handleChange}
                        
                                                helperText={
                                                    errors.no_surat && errors.no_surat.message
                                                }
                                                error={errors.no_surat && true}
                                                inputRef={register}
                                            />
                                        </Grid>
                                        <Grid 
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <InputLabel htmlFor="outlined-age-native-simple">Tanggal Surat</InputLabel>
                                            <div className={classes.searchRoot}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        fullWidth
                                                        ampm={false}
                                                        variant="outlined"
                                                        name="mailDate"
                                                        format="dd MMMM yyyy"
                                                        value={mailDate.view} 
                                                        onChange={handleMailDate} 
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Grid>
                                        <Grid 
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <TextField 
                                                fullWidth
                                                label="Pengolah"
                                                className={classes.textInput}
                                                name="pengolah"
                                                defaultValue={formState.values.pengolah || ''}
                                                onChange={handleChange}
                        
                                                helperText={
                                                    errors.pengolah && errors.pengolah.message
                                                }
                                                error={errors.pengolah && true}
                                                inputRef={register}
                                            />
                                        </Grid>
                                        <Grid 
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <TextField 
                                                fullWidth
                                                label="Tujuan Surat"
                                                className={classes.textInput}
                                                name="tujuan_surat"
                                                defaultValue={formState.values.tujuan_surat || ''}
                                                onChange={handleChange}
                        
                                                helperText={
                                                    errors.tujuan_surat && errors.tujuan_surat.message
                                                }
                                                error={errors.tujuan_surat && true}
                                                inputRef={register}
                                            />
                                        </Grid>
                                        <Grid 
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <TextField 
                                                fullWidth
                                                label="Perihal"
                                                className={classes.textInput}
                                                name="perihal"
                                                defaultValue={formState.values.perihal || ''}
                                                onChange={handleChange}
                        
                                                helperText={
                                                    errors.perihal && errors.perihal.message
                                                }
                                                error={errors.perihal && true}
                                                inputRef={register}
                                            />
                                        </Grid>
                                        <Grid 
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <TextField 
                                                fullWidth
                                                label="Status Surat"
                                                className={classes.textInput}
                                                name="status_type"
                                                defaultValue={formState.values.status_type || ''}
                                                onChange={handleChange}
                                                helperText={
                                                    errors.status_type && errors.status_type.message
                                                }
                                                error={errors.status_type && true}
                                                inputRef={register}
                                                select
                                            >
                                                <MenuItem value="biasa">Biasa</MenuItem>
                                                <MenuItem value="kilat">Kilat</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item lg={12}>
                        <Card>
                            <CardHeader 
                                title="Upload File" 
                                classes={{
                                    title: classes.text
                                }}
                            />
                            <CardContent>
                                <UploadFile value={banner} handleChangeFile={handleChangeFile} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    spacing={4}
                    justify="space-between"
                >
                    <Grid item>
                        <Typography variant="subtitle2">
                            <InfoIcon fontSize="small"/> Pastikan semua data terisi lengkap dan benar!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" className={classes.btnPrimary}>Simpan</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    </Fragment>
}

const mapStateToProps = state => ({
    suratMasuk: state.suratMasuk
})

export default connect(mapStateToProps, { updateOutboxMail })(EditSuratMasuk)
import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'

export const getBagian = () => async dispatch => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}api/bagian`

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_BAGIAN,
            payload: res.data.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_BAGIAN,
            payload: error
        })
    }
    
}
import * as actions from '../actions/actionTypes'

const initialState = {
    inboxMail: null,
    inboxMailData: null,
    inboxMailUpdate: null,
    downloadBuktiTerima: null,
    downloadDetailSurat: null,
    dispositionChoiceList: null,
    dispositionInboxMail: null,
    history: null,
    inboxMailDone: null,
    loadingInboxMailDone: true,
    loadingHistory: true,
    loadingDispositionInboxMail: false,
    loadingDownloadDetailSurat: false,
    loadingDownloadBuktiTerima: false,
    loadingUpdateInboxMail: false,
    loadingPostInboxMail: false,
    loadingInboxMail: true,
    loadingDispositionChoiceList: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.GET_INBOX_MAIL:
            return {
                ...state,
                inboxMail: payload,
                loadingInboxMail: false
            }
        case actions.GET_INBOX_MAIL_DONE:
            return {
                ...state,
                inboxMailDone: payload,
                loadingInboxMailDone: false
            }
        case actions.POST_INBOX_MAIL_START:
            return {
                ...state,
                loadingPostInboxMail: true
            }
        case actions.POST_INBOX_MAIL_SUCCESS:
            return {
                ...state,
                inboxMailData: payload,
                loadingPostInboxMail: false
            }
        case actions.POST_INBOX_MAIL_FAIL:
            return {
                ...state,
                inboxMailData: payload,
                loadingPostInboxMail: false
            }
        case actions.UPDATE_INBOX_MAIL_START:
            return {
                ...state,
                loadingUpdateInboxMail: true
            }
        case actions.UPDATE_INBOX_MAIL_SUCCESS:
            return {
                ...state,
                inboxMailUpdate: payload,
                loadingUpdateInboxMail: false
            }
        case actions.UPDATE_INBOX_MAIL_FAIL:
            return {
                ...state,
                inboxMailUpdate: payload,
                loadingUpdateInboxMail: false
            }
        case actions.DOWNLOAD_BUKTI_TERIMA_START:
            return {
                ...state,
                loadingDownloadBuktiTerima: true
            }
        case actions.DOWNLOAD_BUKTI_TERIMA_END:
            return {
                ...state,
                downloadBuktiTerima: payload,
                loadingDownloadBuktiTerima: false
            }
        case actions.DOWNLOAD_DETAIL_SURAT_START:
            return {
                ...state,
                loadingDownloadDetailSurat: true
            }
        case actions.DOWNLOAD_DETAIL_SURAT_END:
            return {
                ...state,
                downloadDetailSurat: payload,
                loadingDownloadDetailSurat: false
            }
        case actions.DISPOSITION_INBOX_MAIL_START:
            return {
                ...state,
                loadingDispositionInboxMail: true
            }
        case actions.DISPOSITION_INBOX_MAIL_SUCCESS:
            return {
                ...state,
                dispositionInboxMail: payload,
                loadingDispositionInboxMail: false
            }
        case actions.DISPOSITION_INBOX_MAIL_FAIL:
            return {
                ...state,
                error: payload,
                loadingDispositionInboxMail: false
            }
        case actions.GET_DISPOSITION_CHOICE:
            return {
                ...state,
                dispositionChoiceList: payload,
                loadingDispositionChoiceList: false
            }
        case actions.GET_HISTORY:
            return {
                ...state,
                history: payload,
                loadingHistory: false
            }
        default:
            return state
    }
}
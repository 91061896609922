import * as actions from '../actions/actionTypes'

const initialState = {
    outboxMail: null,
    outboxMailData: null,
    outboxMailUpdate: null,
    outboxMailAcc: null,
    historyOutboxMail: null,
    reportList: null,
    loadingReportList: true,
    loadingHistoryOutboxMail: true,
    loadingOutboxAcc: false,
    loadingUpdateOutboxMail: false,
    loadingPostOutboxMail: false,
    loadingOutboxMail: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.GET_OUTBOX_MAIL:
            return {
                ...state,
                outboxMail: payload,
                loadingOutboxMail: false
            }
        case actions.GET_REPORT_OUTBOXMAIL:
            return {
                ...state,
                reportList: payload,
                loadingReportList: false
            }
        case actions.POST_OUTBOX_MAIL_START:
            return {
                ...state,
                loadingPostOutboxMail: true
            }
        case actions.POST_OUTBOX_MAIL_SUCCESS:
            return {
                ...state,
                outboxMailData: payload,
                loadingPostOutboxMail: false
            }
        case actions.POST_OUTBOX_MAIL_FAIL:
            return {
                ...state,
                outboxMailData: payload,
                loadingPostOutboxMail: false
            }
        case actions.UPDATE_OUTBOX_MAIL_START:
            return {
                ...state,
                loadingUpdateOutboxMail: true
            }
        case actions.UPDATE_OUTBOX_MAIL_SUCCESS:
            return {
                ...state,
                outboxMailUpdate: payload,
                loadingUpdateOutboxMail: false
            }
        case actions.UPDATE_OUTBOX_MAIL_FAIL:
            return {
                ...state,
                outboxMailUpdate: payload,
                loadingUpdateOutboxMail: false
            }
        case actions.ACC_OUTBOX_MAIL_START:
            return {
                ...state,
                loadingOutboxAcc: true
            }
        case actions.ACC_OUTBOX_MAIL_SUCCESS:
            return {
                ...state,
                outboxMailAcc: payload,
                loadingOutboxAcc: false
            }
        case actions.ACC_OUTBOX_MAIL_FAIL:
            return {
                ...state,
                outboxMailAcc: payload,
                loadingOutboxAcc: false
            }
        case actions.GET_HISTORY_OUTBOX_MAIL:
            return {
                ...state,
                historyOutboxMail: payload,
                loadingHistoryOutboxMail: false
            }
        default:
            return state
    }
}
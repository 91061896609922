import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList';
import { CardHeader, CardContent, Dialog, DialogTitle, DialogContent, Paper, Divider, InputBase } from '@material-ui/core';
import DetailSuratKeluar from '../DetailSuratKeluar'
import { Link as RouterLink } from 'react-router-dom'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'no_surat', disablePadding: false, label: 'Nomor Surat' },
  { id: 'tgl_surat', disablePadding: false, label: 'Tanggal Surat' },
  { id: 'perihal', disablePadding: false, label: 'Perihal' },
  { id: 'tujuan_surat', disablePadding: false, label: 'Tujuan Surat' },
  { id: 'pengolah', disablePadding: false, label: 'Pengolah' },
  { id: 'status', disablePadding: false, label: 'Status' },
  { id: 'action', disablePadding: false, label: 'Aksi' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  text: {
      fontFamily: 'Montserrat',
      color: '#000000'
  },
  searchRoot: {
		padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
		width: '400px',
		// marginTop: theme.spacing(2)
	},
	input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '100%'
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
))

const TableSuratKeluar = props => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const {
    outboxMail,
    setKeyword,
    keyword,
    page,
    setPage,
    handleChangeSearch,
  } = props

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = outboxMail.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Dialog Detail Surat
  const [ openDialogDetailSurat, setOpenDialogDetailSurat ] = useState({
    open: false,
    item: null
  })

  const handleOpenDetailSurat = e => {
    setOpenDialogDetailSurat({
      open: true,
      item: e
    })
  }

  const handleCloseDetailSurat = e => {
    setOpenDialogDetailSurat({
      open: false,
      item: e
    })
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, outboxMail.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Card className={classes.paper}>
        <CardHeader 
            title="Memo Surat Keluar"
            classes={{
                title: classes.text
            }}
            action={
              <Paper component="form" className={classes.searchRoot}>
                  <IconButton type="button" className={classes.iconButton} aria-label="search">
                      <SearchIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />
                  <InputBase
                      className={classes.input}
                      name="pesan"
                      value={keyword}
                      onChange={handleChangeSearch}
                      placeholder="Cari Memo Surat Keluar"
                      inputProps={{ 'aria-label': 'Cari Customer' }}
                  />
              </Paper>
            }
        />
        <CardContent>
            <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='small'
                aria-label="enhanced table"
            >
                <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={outboxMail.length}
                />
                <TableBody>
                {stableSort(outboxMail, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                        >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.no_surat}
                        </TableCell>
                        <TableCell align="left">{row.tanggal_surat}</TableCell>
                        <TableCell align="left">{row.perihal}</TableCell>
                        <TableCell align="left">{row.tujuan_surat}</TableCell>
                        <TableCell align="left">{row.pengolah}</TableCell>
                        <TableCell align="left">{row.status_surat.status}</TableCell>
                        <TableCell align="left">
                          <Tooltip arrow title="History Surat Keluar">
                            <IconButton component={CustomRouterLink} to={`/memo-surat-keluar/${row.id}/history`}>
                              <img src={`${process.env.PUBLIC_URL}/images/icon/Chart.svg`} alt="History" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Detail Surat Keluar">
                            <IconButton  onClick={() => handleOpenDetailSurat(row)}>
                              <img src={`${process.env.PUBLIC_URL}/images/icon/edit.svg`} alt="Detail Surat Keluar" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        </TableRow>
                    );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={outboxMail.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </CardContent>
      </Card>
      <Dialog
        open={openDialogDetailSurat.open}
        onClose={() => handleCloseDetailSurat(openDialogDetailSurat.item)}
      >
        <DialogTitle>
          {openDialogDetailSurat.item !== null && (
            <Typography>
              {openDialogDetailSurat.item.perihal}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <DetailSuratKeluar
            outboxMail={openDialogDetailSurat.item}
            setKeyword={setKeyword}
            handleCloseDetailSurat={handleCloseDetailSurat}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TableSuratKeluar
import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Card,
    CardContent,
    CardHeader,
    makeStyles,
} from '@material-ui/core'
import {options} from './chart'
import './roundedBar'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    text: {
        color: '#000000',
        fontFamily: 'Montserrat'
    }
}))

const GrafikOutboxMail = props => {
    const classes = useStyles()
    const {
        grafikOutboxMail
    } = props

    var data = {}
    var value=[]
    var bulan=[];

    // if(!loadingTransactionSales || grafikTransactionSales !== null){
        for (var i = 0; i < grafikOutboxMail.length; i++) {
            // bulan.push(grafikOutboxMail[i].date);
            // var date = new Date(grafikOutboxMail[i].date)
            bulan.push(moment(grafikOutboxMail[i].date).format('DD/MM'));
            value.push(grafikOutboxMail[i].value);
        }
    
        data = {
            labels: bulan,
            datasets: [
              {
                label : 'Jumlah Pekerjaan',
                data: value,
                backgroundColor: '#2285DF',
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderRadius: 10
              }
            ]
        };
    // }
    
    return(
        <div>
                <Card>
                    <CardHeader 
                        title={`Grafik Surat Masuk`}
                        classes={{
                            title: classes.text
                        }}
                    />
                    <CardContent>
                        <Bar
                            // width={100}
                            // height={400}
                            data={data}
                            options={options}
                        />
                    </CardContent>
                </Card>

        </div>
    )
}

export default GrafikOutboxMail
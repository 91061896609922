import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'

import { connect } from 'react-redux'
import { getOutboxMail } from '../../store/actions/suratKeluar'

import TableSuratKeluar from './TableSuratKeluar'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Montserrat',
        color: '#000000'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    textButton: {
        fontFamily: 'Montserrat',
        color: '#FFFFFF'
    }
}))

const SuratKeluar = props => {
    const classes = useStyles()
    const {
        getOutboxMail,
        suratKeluar: {
            outboxMail,
            loadingOutboxMail
        }
    } = props

    const [ keyword, setKeyword ] = useState('')
    const [page, setPage] = useState(0);

    const handleChangeSearch = event => {
        setKeyword(event.target.value)
        setPage(0)
    }

    useEffect(() => {
        getOutboxMail(keyword)
    }, [ getOutboxMail, keyword ])

    return(
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
                justify="space-between"
            >
                <Grid item>  
                    <Typography variant="h4" className={classes.title}>Memo Surat Keluar</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
            >
                <Grid item lg={12}>
                    {!loadingOutboxMail && outboxMail !== null ?(
                        <TableSuratKeluar
                            outboxMail={outboxMail}
                            setKeyword={setKeyword}
                            keyword={keyword}
                            page={page}
                            setPage={setPage}
                            handleChangeSearch={handleChangeSearch}
                        />
                    ):(
                        <Skeleton variant="rect"></Skeleton>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => ({
    suratKeluar: state.suratKeluar
})

export default connect(mapStateToProps, { getOutboxMail })(SuratKeluar)
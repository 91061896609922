import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardActions, 
    CardContent, 
    Grid, 
    InputLabel, 
    Typography,
    Button,
    Divider,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// redux
import { connect } from 'react-redux'
import { accOutboxMail } from '../../../store/actions/suratKeluar'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    text: {
        color: '#000000',
        fontFamily: 'Montserrat'
    },
    textWhite: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    buttonSmall: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginTop: theme.spacing(1)
    },
    divider: {
        color: '#000000',
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
}))

const DetailSuratKeluar = props => {
    const classes = useStyles()
    const history = useHistory()
    const {
        outboxMail,
        accOutboxMail,
        handleCloseDetailSurat,
        setKeyword,
        suratKeluar: {
            loadingOutboxAcc,
        },
    } = props

    const [ openDialogConfirmation, setOpenDialogConfirmation ] = useState({
        open: false,
        item: null
    })

    const handleOpenDialogConfirmation = e => {
        setOpenDialogConfirmation({
            open : true,
            item: e
        })
    }

    const handleCloseDialogConfirmation = e => {
        setOpenDialogConfirmation({
            open : false,
            item: e
        })
    }

    const onSubmit = event => {
        accOutboxMail(event.id, setKeyword, outboxMail.no_surat, history)
        handleCloseDetailSurat(outboxMail)
        handleCloseDialogConfirmation()
    }

    return loadingOutboxAcc ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <Card className={classes.root}>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Tgl Terima</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {moment(outboxMail.tanggal_terima).format('DD MMMM yyyy')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Tgl/No Surat:</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {moment(outboxMail.tanggal_surat).format('DD MMMM yyyy')} | {outboxMail.no_surat}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Perihal:</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {outboxMail.perihal}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Pengolah:</InputLabel>
                        <Typography variant="body" className={classes.text}>
                            {outboxMail.pengolah}
                        </Typography>
                    </Grid>
                    <Divider variant="middle" className={classes.divider} />
                    <Grid
                        item
                        lg={12}
                    >
                        <InputLabel className={classes.text}>Lampiran Dokumen:</InputLabel>
                        <Button size="small" className={classes.buttonSmall} target="_blank" href={outboxMail.file_path}>
                            <div className={classes.textWhite}>
                                Download
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    {sessionStorage.getItem('role') === 'manager' && (
                        <>
                            <Grid item>
                                <Button className={classes.button} onClick={() => handleOpenDialogConfirmation(outboxMail)}>
                                    <div className={classes.textWhite}>
                                        Konfirmasi Surat
                                    </div>
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardActions>
        </Card>
        <Dialog
            open={openDialogConfirmation.open}
            onClose={() => handleCloseDialogConfirmation(openDialogConfirmation.item)}
        >
            <DialogTitle>
                <Typography>
                    Konfirmasi Surat Keluar
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Apakah anda yakin untuk mengkonfirmasi surat ini?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={() => handleCloseDialogConfirmation(openDialogConfirmation.item)}>
                    Tidak
                </Button>
                <Button className={classes.button} onClick={() => onSubmit(openDialogConfirmation.item)}>
                    Ya
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>
}

const mapStateToProps = state => ({
    suratKeluar: state.suratKeluar,
})

export default connect(mapStateToProps, { accOutboxMail })(DetailSuratKeluar)
import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'

export const getStaffMin = (keyword) => async dispatch => {
    let endpoint = null
    if(keyword){
        endpoint = `${process.env.REACT_APP_BASE_URL}api/paur/user?keyword=${keyword}`
    }else{
        endpoint = `${process.env.REACT_APP_BASE_URL}api/paur/user`
    }
  
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
            'Content-Type': 'application/json', 
            'Accept' : 'application/json', 
            'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_STAFF_MIN,
            payload: res.data.data
        })

    } catch (error) {
        // dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_STAFF_MIN,
            payload: error
        })
    }
      
}

export const getDetailStaffMin = (id, setFormState) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/paur/user/${id}`
  
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
            'Content-Type': 'application/json', 
            'Accept' : 'application/json', 
            'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_DETAIL_STAFF_MIN,
            payload: res.data.data
        })
        setFormState(formState => ({
            ...formState,
            values: {
                name: res.data.data.name,
                email: res.data.data.email,
                username: res.data.data.username,
            }
        }));

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_DETAIL_STAFF_MIN,
            payload: error
        })
    }
      
}

export const addStaffMin = (formData, history) => async dispatch => {
    dispatch({
        type: actions.ADD_STAFF_MIN_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/paur/user`
    const myData = new FormData()
    myData.set('name', formData.name)
    myData.set('username', formData.username)
    myData.set('email', formData.email)
    myData.set('password', formData.password)
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'multipart/form-data', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.ADD_STAFF_MIN_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/manajemen/staff-min')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.ADD_STAFF_MIN_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const updateStaffMin = (id, formData, history) => async dispatch => {
    dispatch({
        type: actions.UPDATE_STAFF_MIN_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/paur/user/${id}`
    const myData = new FormData()
    myData.set('name', formData.name)
    myData.set('username', formData.username)
    myData.set('email', formData.email)
    if(formData.password){
        myData.set('password', formData.password)
    }
  
      try {
          const res = await axios({
              url: endpoint,
              method: "PUT",
              data: myData,
              headers: { 
                'Content-Type': 'application/x-www-form-urlencoded', 
                'Accept' : 'application/x-www-form-urlencoded', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.UPDATE_STAFF_MIN_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/manajemen/staff-min')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.UPDATE_STAFF_MIN_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}
import * as actions from '../actions/actionTypes'

const initialState = {
    memoList: null,
    documentDownloadList: null,
    dispositionMemo: null,
    staffMin: null,
    loadingStaffMin: true,
    loadingDispositionMemo: false,
    loadingDownloadDocumentDisposition: false,
    loadingMemoList: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.GET_MEMO:
            return {
                ...state,
                memoList: payload,
                loadingMemoList: false
            }
        case actions.DOWNLOAD_DOCUMENT_DISPOSITION_START:
            return {
                ...state,
                loadingDownloadDocumentDisposition: true
            }
        case actions.DOWNLOAD_DOCUMENT_DISPOSITION_SUCCESS:
            return {
                ...state,
                documentDownloadList: payload,
                loadingDownloadDocumentDisposition: false
            }
        case actions.DOWNLOAD_DOCUMENT_DISPOSITION_END:
            return {
                ...state,
                error: payload,
                loadingDownloadDocumentDisposition: false
            }
        case actions.DISPOSITION_INBOX_MAIL_START:
            return {
                ...state,
                loadingDispositionMemo: true
            }
        case actions.DISPOSITION_INBOX_MAIL_SUCCESS:
            return {
                ...state,
                dispositionMemo: payload,
                loadingDispositionMemo: false
            }
        case actions.DISPOSITION_INBOX_MAIL_FAIL:
            return {
                ...state,
                error: payload,
                loadingDispositionMemo: false
            }
        case actions.GET_STAFF_MIN:
            return {
                ...state,
                staffMin: payload,
                loadingStaffMin: false
            }
        default:
            return state
    }
}
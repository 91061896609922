import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import profile from '../../reducers/profile'
import suratMasuk from './suratMasuk'
import suratKeluar from './suratKeluar'
import bagian from './bagian'
import memo from './memo'
import uploadTask from './uploadTask'
import staffMin from './staffMin'
import dashboard from './dashboard'

export default combineReducers({
    alert,
    auth,
    profile,
    suratMasuk,
    suratKeluar,
    bagian,
    memo,
    uploadTask,
    staffMin,
    dashboard
})
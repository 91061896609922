import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
	// Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyBeoS91vtP-DYGoMoTVZZrHEpdb2pJVJu8",
  authDomain: "simantap-146e0.firebaseapp.com",
  projectId: "simantap-146e0",
  storageBucket: "simantap-146e0.appspot.com",
  messagingSenderId: "718058254689",
  appId: "1:718058254689:web:0cd06fb8a33bfb2c5a9520",
  measurementId: "G-1NEG9M8P01"
});

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    "BOxVztHiPYPOwY7adO7XKx-jfpZoPV6mW5LI3WV7JETwub7rvpiLSDTGmjFruYVyxBOXjUzfWA-V53jigPlWY60"
  );
}

export { messaging };

import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'

export const uploadTask = (id, formData, file, history) => async dispatch => {
    dispatch({
        type: actions.UPLOAD_TASK_START,
    })

    const endpoint = `${process.env.REACT_APP_BASE_URL}api/staffmin/upload-file/${id}`
    const myData = new FormData()
    myData.set('catatan', formData.catatan)
    myData.set('file', file)
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.UPLOAD_TASK_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/memo')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.UPLOAD_TASK_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}
import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'

export const getOutboxMail = (keyword) => async dispatch => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-keluar?keyword=${keyword}`

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_OUTBOX_MAIL,
            payload: res.data.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_OUTBOX_MAIL,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
    
}

export const postOutboxMail = (formData, file, mailDate, history) => async dispatch => {
    dispatch({
        type: actions.POST_OUTBOX_MAIL_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-keluar`
    const myData = new FormData()
    myData.set('no_surat', formData.no_surat)
    myData.set('tanggal_surat', mailDate)
    myData.set('tujuan_surat', formData.tujuan_surat)
    myData.set('pengolah', formData.pengolah)
    myData.set('perihal', formData.perihal)
    myData.set('status_type', formData.status_type)
    myData.set('file', file)
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'multipart/form-data', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.POST_OUTBOX_MAIL_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/surat-keluar')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.POST_OUTBOX_MAIL_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const updateOutboxMail = (formData, file, mailDate, history, id) => async dispatch => {
    dispatch({
        type: actions.UPDATE_OUTBOX_MAIL_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-keluar/${id}`
    const myData = new FormData()
    myData.set('_method', 'put')
    myData.set('no_surat', formData.no_surat)
    myData.set('tanggal_surat', mailDate)
    myData.set('tujuan_surat', formData.tujuan_surat)
    myData.set('pengolah', formData.pengolah)
    myData.set('perihal', formData.perihal)
    myData.set('status_type', formData.status_type)
    if(file.length > 0){
        myData.set('file', file)
    }
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'multipart/form-data', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.UPDATE_OUTBOX_MAIL_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/surat-keluar')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.UPDATE_OUTBOX_MAIL_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const accOutboxMail = (id, setKeyword, noSurat, history) => async dispatch => {
    dispatch({
        type: actions.ACC_OUTBOX_MAIL_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-keluar/confirm/${id}`
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.ACC_OUTBOX_MAIL_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/memo-surat-keluar')
        setKeyword(noSurat)
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.ACC_OUTBOX_MAIL_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const getHistory = (id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-keluar/history/${id}`
  
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
          dispatch({
              type: actions.GET_HISTORY_OUTBOX_MAIL,
              payload: res.data.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.GET_HISTORY_OUTBOX_MAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const getReportOutboxMail = (keyword, startDate, endDate) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/report/surat-keluar?keyword=${keyword}&start_date=${startDate}&end_date=${endDate}`
  
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
          dispatch({
              type: actions.GET_REPORT_OUTBOXMAIL,
              payload: res.data.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.GET_REPORT_OUTBOXMAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}
import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardContent, 
    TextField, 
    Grid, 
    Typography, 
    Button,
    Backdrop,
    CircularProgress,
} from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { addStaffMin } from '../../../store/actions/staffMin'

import '../../../App.css'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    breadcumbs: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(4)
    },
    text: {
        fontFamily: 'Montserrat',
        color: '#000000'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
}))

const StaffMinSchema = yup.object().shape({
	username: yup.string().required("Username harus diisi"),
	email: yup.string().required("Email harus diisi"),
	password: yup.string().required("Password harus diisi"),
});

const CreateStaffMin = props => {
    const classes = useStyles()
    const {
        addStaffMin,
        staffMin: {
            loadingCreateStaffMin
        }
    } = props
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(StaffMinSchema)
    });

    const [ formState, setFormState ] = useState({
        values: {}
    })

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: 
                event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
    };
    
        
    const onSubmit = (event) => {
        // console.log(formState.values, banner, mailDate.submit, accDate.submit);
        addStaffMin(formState.values, history)
	}
    
    return loadingCreateStaffMin ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <div className={classes.breadcumbs}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/manajemen/staff-min" >
                    Staff Min
                </Link>
                <Link
                    color="textPrimary"
                    to="/manajemen/staff-min/create"
                    aria-current="page"
                >
                    Form Staff Min
                </Link>
            </Breadcrumbs>
        </div>
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
            >
                <Grid item>  
                    <Typography variant="h4" className={classes.text}>Form Staff Min</Typography>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid 
                        item 
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                    >
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid 
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <TextField 
                                            fullWidth
                                            label="Nama Staff"
                                            className={classes.textInput}
                                            name="name"
                                            defaultValue={formState.values.name || ''}
                                            onChange={handleChange}
                                            helperText={
                                                errors.name && errors.name.message
                                            }
                                            error={errors.name && true}
                                            inputRef={register}
                                        />
                                    </Grid>
                                    <Grid 
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <TextField 
                                            fullWidth
                                            label="Username Staff"
                                            className={classes.textInput}
                                            name="username"
                                            defaultValue={formState.values.username || ''}
                                            onChange={handleChange}
                                            helperText={
                                                errors.username && errors.username.message
                                            }
                                            error={errors.username && true}
                                            inputRef={register}
                                        />
                                    </Grid>
                                    <Grid 
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <TextField 
                                            fullWidth
                                            label="Email"
                                            className={classes.textInput}
                                            name="email"
                                            defaultValue={formState.values.email || ''}
                                            onChange={handleChange}
                                            helperText={
                                                errors.email && errors.email.message
                                            }
                                            error={errors.email && true}
                                            inputRef={register}
                                        />
                                    </Grid>
                                    <Grid 
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <TextField 
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            className={classes.textInput}
                                            name="password"
                                            defaultValue={formState.values.password || ''}
                                            onChange={handleChange}
                    
                                            helperText={
                                                errors.password && errors.password.message
                                            }
                                            error={errors.password && true}
                                            inputRef={register}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    spacing={4}
                    justify="space-between"
                >
                    <Grid 
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                    >
                        <Button type="submit" variant="contained" className={classes.btnPrimary}>Simpan</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    </Fragment>
}

const mapStateToProps = state => ({
    staffMin: state.staffMin
})

export default connect(mapStateToProps, { addStaffMin })(CreateStaffMin)
import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const authStart = () => {
  return {
    type: actions.AUTH_START
  }
}

export const authSuccess = (token) => {
  return {
    type: actions.AUTH_SUCCESS,
    tokenId: token
  }
}

export const authFail = (error) => {
  return {
    type: actions.AUTH_FAIL,
    error: error
  }
}

export const auth = (username, password, token, history) => {
  return dispatch => {
    dispatch(authStart())
    const authData = {
      username: username,
      password: password,
      device_token: token
    }

    axios.post('api/login', authData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then(response => {
        // if(response.data.code === "200"){
          sessionStorage.setItem('access_token', response.data.original.access_token)
          sessionStorage.setItem('data', JSON.stringify(response.data.original.user_data))
          sessionStorage.setItem('role', response.data.original.user_data.role.role_name)
          sessionStorage.setItem('bagian', response.data.original.bagian)

          history.push(`/dashboard`);
          dispatch(authSuccess(response.data.token))
        // }else{
          // dispatch(setAlert(response.data.message, "error"))
        // }
      })
      .catch(err => {
        // dispatch(authFail(err.response.data.msg_str))
        // dispatch(setAlert(err.response.data.msg_str, 'error'))
        dispatch(setAlert(err.response.data.message, "error"))
        console.log(err)
      })
  }
}
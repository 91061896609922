export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_DIALOG_BOX = 'SET_DIALOG_BOX';
export const REMOVE_DIALOG_BOX = 'REMOVE_DIALOG_BOX';

// Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD"

// Surat Masuk
export const GET_INBOX_MAIL = "GET_INBOX_MAIL"
export const POST_INBOX_MAIL_START = "POST_INBOX_MAIL_START"
export const POST_INBOX_MAIL_SUCCESS = "POST_INBOX_MAIL_SUCCESS"
export const POST_INBOX_MAIL_FAIL = "POST_INBOX_MAIL_FAIL"

export const UPDATE_INBOX_MAIL_START = "UPDATE_INBOX_MAIL_START"
export const UPDATE_INBOX_MAIL_SUCCESS = "UPDATE_INBOX_MAIL_SUCCESS"
export const UPDATE_INBOX_MAIL_FAIL = "UPDATE_INBOX_MAIL_FAIL"

export const DOWNLOAD_BUKTI_TERIMA_START = "DOWNLOAD_BUKTI_TERIMA_START"
export const DOWNLOAD_BUKTI_TERIMA_END = "DOWNLOAD_BUKTI_TERIMA_END"

export const DOWNLOAD_DETAIL_SURAT_START = "DOWNLOAD_DETAIL_SURAT_START"
export const DOWNLOAD_DETAIL_SURAT_END = "DOWNLOAD_DETAIL_SURAT_END"

export const GET_DISPOSITION_CHOICE = "GET_DISPOSITION_CHOICE"

export const DISPOSITION_INBOX_MAIL_START = "DISPOSITION_INBOX_MAIL_START"
export const DISPOSITION_INBOX_MAIL_SUCCESS = "DISPOSITION_INBOX_MAIL_SUCCESS"
export const DISPOSITION_INBOX_MAIL_FAIL = "DISPOSITION_INBOX_MAIL_FAIL"

// Surat Masuk yang sudah selesai
export const GET_INBOX_MAIL_DONE = "GET_INBOX_MAIL_DONE"

// Surat Keluar
export const GET_OUTBOX_MAIL = "GET_OUTBOX_MAIL"
export const POST_OUTBOX_MAIL_START = "POST_OUTBOX_MAIL_START"
export const POST_OUTBOX_MAIL_SUCCESS = "POST_OUTBOX_MAIL_SUCCESS"
export const POST_OUTBOX_MAIL_FAIL = "POST_OUTBOX_MAIL_FAIL"

export const UPDATE_OUTBOX_MAIL_START = "UPDATE_OUTBOX_MAIL_START"
export const UPDATE_OUTBOX_MAIL_SUCCESS = "UPDATE_OUTBOX_MAIL_SUCCESS"
export const UPDATE_OUTBOX_MAIL_FAIL = "UPDATE_OUTBOX_MAIL_FAIL"

export const ACC_OUTBOX_MAIL_START = "ACC_OUTBOX_MAIL_START"
export const ACC_OUTBOX_MAIL_SUCCESS = "ACC_OUTBOX_MAIL_SUCCESS"
export const ACC_OUTBOX_MAIL_FAIL = "ACC_OUTBOX_MAIL_FAIL"

export const GET_REPORT_OUTBOXMAIL = "GET_REPORT_OUTBOXMAIL"

// Bagian
export const GET_BAGIAN = "GET_BAGIAN"

// Memo
export const GET_MEMO = "GET_MEMO"

export const DOWNLOAD_DOCUMENT_DISPOSITION_START = "DOWNLOAD_DOCUMENT_DISPOSITION_START"
export const DOWNLOAD_DOCUMENT_DISPOSITION_SUCCESS = "DOWNLOAD_DOCUMENT_DISPOSITION_SUCCESS"
export const DOWNLOAD_DOCUMENT_DISPOSITION_END = "DOWNLOAD_DOCUMENT_DISPOSITION_END"

export const DISPOSITION_MEMO_START = "DISPOSITION_MEMO_START"
export const DISPOSITION_MEMO_SUCCESS = "DISPOSITION_MEMO_SUCCESS"
export const DISPOSITION_MEMO_FAIL = "DISPOSITION_MEMO_FAIL"

// Paur
export const GET_STAFF_MIN = "GET_STAFF_MIN"

export const ADD_STAFF_MIN_START = "ADD_STAFF_MIN_START"
export const ADD_STAFF_MIN_SUCCESS = "ADD_STAFF_MIN_SUCCESS"
export const ADD_STAFF_MIN_FAIL = "ADD_STAFF_MIN_FAIL"

export const UPDATE_STAFF_MIN_START = "UPDATE_STAFF_MIN_START"
export const UPDATE_STAFF_MIN_SUCCESS = "UPDATE_STAFF_MIN_SUCCESS"
export const UPDATE_STAFF_MIN_FAIL = "UPDATE_STAFF_MIN_FAIL"

export const GET_DETAIL_STAFF_MIN = "GET_DETAIL_STAFF_MIN"

// Staff Min
export const UPLOAD_TASK_START = "UPLOAD_TASK_START"
export const UPLOAD_TASK_SUCCESS = "UPLOAD_TASK_SUCCESS"
export const UPLOAD_TASK_FAIL = "UPLOAD_TASK_FAIL"

// History
export const GET_HISTORY = "GET_HISTORY"
export const GET_HISTORY_OUTBOX_MAIL = "GET_HISTORY_OUTBOX_MAIL"
import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardContent, 
    TextField, 
    Grid, 
    Typography, 
    InputLabel, 
    CardHeader, 
    Button,
    Backdrop,
    CircularProgress,
    MenuItem
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import { Info as InfoIcon } from '@material-ui/icons'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useHistory } from 'react-router-dom'

import UploadFile from './UploadFile'
import { connect } from 'react-redux'
import { postInboxMail } from '../../../store/actions/suratMasuk'

import '../../../App.css'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    breadcumbs: {
        padding: theme.spacing(4)
    },
    text: {
        fontFamily: 'Montserrat',
        color: '#000000'
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
}))

const InboxMailSchema = yup.object().shape({
	no_agenda: yup.string().required("Nomor Agenda harus diisi"),
	// sumber_surat: yup.string().required("Sumber Surat harus diisi"),
	perihal: yup.string().required("Perihal harus diisi"),
});

const FormSuratMasuk = props => {
    const classes = useStyles()
    const {
        postInboxMail,
        suratMasuk: {
            loadingPostInboxMail
        }
    } = props
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(InboxMailSchema)
    });

    const [ formState, setFormState ] = useState({
        values: {}
    })

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: 
                event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
    };

    const submitDefault = moment().format('YYYY/MM/DD')
	const [ mailDate, setMailDate ] = useState({
        submit: submitDefault,
        view: new Date()
        
	});
    const handleMailDate = (date) => {
        const changeDate = moment(date).format('YYYY/MM/DD');
        setMailDate(mailDate => ({
            ...mailDate,
                submit: changeDate,
                view: date
        }));
    };

    const [ accDate, setAccDate ] = useState({
        submit: submitDefault,
        view: new Date()
        
	});
    const handleAccDate = (date) => {
        const changeDate = moment(date).format('YYYY/MM/DD');
        setAccDate(accDate => ({
            ...accDate,
                submit: changeDate,
                view: date
        }));
    };

    const [banner, setBanner] = useState([]);
    const handleChangeFile = event => {
        setBanner(event[0]);
        // let reader = new FileReader();
        // reader.readAsDataURL(event[0]);
        // reader.onload = function(){
        //     setB64(reader.result);
        // }
    };
    
        
    const onSubmit = (event) => {
        // console.log(formState.values, banner, mailDate.submit, accDate.submit);
        postInboxMail(formState.values, banner, mailDate.submit, accDate.submit, history)
	}
    
    return loadingPostInboxMail ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <div className={classes.breadcumbs}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/surat-masuk" >
                    Surat Masuk
                </Link>
                <Link
                    color="textPrimary"
                    tp="/surat-masuk/form"
                    
                    aria-current="page"
                >
                    Form Surat Masuk
                </Link>
            </Breadcrumbs>
        </div>
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
            >
                <Grid item>  
                    <Typography variant="h4" className={classes.text}>Form Entri Surat Masuk</Typography>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item lg={12}>
                        <Card>
							<CardContent>
								<Grid
									container
									spacing={2}
								>
									<Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<TextField 
											fullWidth
											label="Klasifikasi"
											className={classes.textInput}
											name="klasifikasi"
											defaultValue={formState.values.klasifikasi || ''}
											onChange={handleChange}
											helperText={
												errors.klasifikasi && errors.klasifikasi.message
											}
											error={errors.klasifikasi && true}
											inputRef={register}
											select
										>
											<MenuItem value="rahasia">Rahasia</MenuItem>
											<MenuItem value="biasa">Biasa</MenuItem>
										</TextField>
									</Grid>
									<Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<TextField 
											fullWidth
											label="Nomor Agenda"
											className={classes.textInput}
											name="no_agenda"
											defaultValue={formState.values.no_agenda || ''}
											onChange={handleChange}
					
											helperText={
												errors.no_agenda && errors.no_agenda.message
											}
											error={errors.no_agenda && true}
											inputRef={register}
										/>
									</Grid>
									<Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<InputLabel htmlFor="outlined-age-native-simple">Tanggal Surat</InputLabel>
										<div className={classes.searchRoot}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DatePicker
													fullWidth
													ampm={false}
													variant="outlined"
													name="mailDate"
													format="dd MMMM yyyy"
													value={mailDate.view} 
													onChange={handleMailDate} 
												/>
											</MuiPickersUtilsProvider>
										</div>
									</Grid>
									<Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<InputLabel htmlFor="outlined-age-native-simple">Tanggal Terima</InputLabel>
										<div className={classes.searchRoot}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DatePicker
													fullWidth
													disableFuture
													ampm={false}
													variant="outlined"
													name="accDate"
													format="dd MMMM yyyy"
													value={accDate.view} 
													onChange={handleAccDate} 
												/>
											</MuiPickersUtilsProvider>
										</div>
									</Grid>
									{/* <Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<TextField 
											fullWidth
											label="Surat Dari"
											className={classes.textInput}
											name="sumber_surat"
											defaultValue={formState.values.sumber_surat || ''}
											onChange={handleChange}
					
											helperText={
												errors.sumber_surat && errors.sumber_surat.message
											}
											error={errors.sumber_surat && true}
											inputRef={register}
										/>
									</Grid> */}
									{/* <Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<TextField 
											fullWidth
											label="Tujuan Surat"
											className={classes.textInput}
											name="tujuan_surat"
											defaultValue={formState.values.tujuan_surat || ''}
											onChange={handleChange}
					
											helperText={
												errors.tujuan_surat && errors.tujuan_surat.message
											}
											error={errors.tujuan_surat && true}
											inputRef={register}
										/>
									</Grid> */}
									<Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<TextField 
											fullWidth
											label="Perihal"
											className={classes.textInput}
											name="perihal"
											defaultValue={formState.values.perihal || ''}
											onChange={handleChange}
					
											helperText={
												errors.perihal && errors.perihal.message
											}
											error={errors.perihal && true}
											inputRef={register}
										/>
									</Grid>
									<Grid 
										item
										lg={6}
										md={6}
										sm={6}
										xs={12}
									>
										<TextField 
											fullWidth
											label="Status Surat"
											className={classes.textInput}
											name="status_type"
											defaultValue={formState.values.status_type || ''}
											onChange={handleChange}
											helperText={
												errors.status_type && errors.status_type.message
											}
											error={errors.status_type && true}
											inputRef={register}
											select
										>
											<MenuItem value="biasa">Biasa</MenuItem>
											<MenuItem value="kilat">Kilat</MenuItem>
										</TextField>
									</Grid>
								</Grid>
							</CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item lg={12}>
                        <Card>
                            <CardHeader 
                                title="Upload File" 
                                classes={{
                                    title: classes.text
                                }}
                            />
                            <CardContent>
                                <UploadFile value={banner} handleChangeFile={handleChangeFile} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    spacing={4}
                    justify="space-between"
                >
                    <Grid item>
                        <Typography variant="subtitle2">
                            <InfoIcon fontSize="small"/> Pastikan semua data terisi lengkap dan benar!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" className={classes.btnPrimary}>Simpan</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    </Fragment>
}

const mapStateToProps = state => ({
    suratMasuk: state.suratMasuk
})

export default connect(mapStateToProps, { postInboxMail })(FormSuratMasuk)
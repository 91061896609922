import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardActions, 
    CardContent, 
    Grid, 
    Button,
    Backdrop,
    CircularProgress,
    TextField,
    MenuItem,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@material-ui/core'
import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import * as yup from "yup";
import { useHistory } from 'react-router-dom'
// redux
import { connect } from 'react-redux'
import { dispositionMemo } from '../../../../store/actions/memo'
import { getStaffMin } from '../../../../store/actions/staffMin'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    text: {
        color: '#000000',
        fontFamily: 'Montserrat'
    },
    textWhite: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    buttonSmall: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginTop: theme.spacing(1)
    },
    divider: {
        color: '#000000',
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
        chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
        chip: {
        margin: 2,
    },
}))

// const DispositionMemo = yup.object().shape({
// 	kepada: yup.string().required("Ditujukan harus diisi"),
// });

const Disposisi = props => {
    const classes = useStyles()
    const history = useHistory()
    const { 
        inboxData,
        handleCloseDialogDisposisi,
        handleCloseDetailSurat,
        dispositionMemo,
        dispositionChoiceList,
        getStaffMin,
        setKeyword,
        staffMin: {
            staffMin,
            loadingStaffMin
        },
        memo: {
            loadingDispositionMemo,
        }
    } = props
    const { handleSubmit } = useForm();

    const [ formState, setFormState ] = useState({
        values: {}
    })

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: 
                event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
    };

    const onSubmit = e => {
        console.log(inboxData.disposable_id, formState.values)
        dispositionMemo(inboxData.disposable_id, formState.values, history, setKeyword, inboxData.disposable.no_surat)
        handleCloseDialogDisposisi()
        handleCloseDetailSurat(inboxData)
    }

    const bagian = JSON.parse(sessionStorage.getItem('data'))

    useEffect(() => {
        // if(bagian.bagian.status_bagian === 'paur'){
            getStaffMin()
        // }
    }, [getStaffMin])

	const [ stateWord, setStateWord ] = useState({
		kelengkapan: false,
		prosedur: false,
		laporkan: false,
		datakan: false,
		tindaklanjut: false,
		wakili: false,
		lainnya: false
	})

	const handleChangeWord = (event) => {
		setStateWord({ ...stateWord, [event.target.name]: event.target.checked });
	};

	const { kelengkapan, prosedur, laporkan, datakan, tindaklanjut, wakili, lainnya } = stateWord

    return loadingDispositionMemo ?
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <Card className={classes.root}>

            <CardContent>
                <Grid
                    container
                    spacing={2}
                >
                    {bagian.bagian.status_bagian === 'karo' || bagian.bagian.status_bagian === 'kabag' ? (
                        <Grid
                            item
                            lg={12}
                        >
                            <TextField
                                fullWidth
                                select
                                name="kepada"
                                value={formState.values.kepada || ''}
                                onChange={handleChange}
                                placeholder="Pilih Bagian"
                                label="Disposisikan Ke"
                                // helperText={
                                //     errors.kepada && errors.kepada.message
                                // }
                                // error={errors.kepada && true}
                                // inputRef={register}
                            >
                                {dispositionChoiceList.map(item => (
                                    <MenuItem value={item.id}>{item.nama}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    ):(
                        <div></div>
                    )}
                    {/* {bagian.bagian.status_bagian === 'karo' || bagian.bagian.status_bagian === 'kabag' || bagian.bagian.status_bagian === 'paur' ? (
                        <Grid
                            item
                            lg={12}
                        >
                            <TextField
                                fullWidth
                                name="catatan"
                                value={formState.values.catatan || ''}
                                onChange={handleChange}
                                placeholder="Buat catatan"
                                label="Catatan"
                                multiline
                                rows={4}
                            />
                        </Grid>
                    ):(
                        <div></div>
                    )} */}
                    {bagian.bagian.seq === 1 || bagian.bagian.seq === 2 || bagian.bagian.seq === 3 || bagian.bagian.seq === 4 ? (
                        <Grid
                            item
                            lg={12}
                        >
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend">Assign responsibility</FormLabel>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox checked={kelengkapan} onChange={handleChangeWord} name="kelengkapan" />}
										label="Cek kelengkapan"
									/>
									<FormControlLabel
										control={<Checkbox checked={prosedur} onChange={handleChangeWord} name="prosedur" />}
										label="Proses sesuai ketentuan dan procedure"
									/>
									<FormControlLabel
										control={<Checkbox checked={laporkan} onChange={handleChangeWord} name="laporkan" />}
										label="Laporkan hasilnya"
									/>
									<FormControlLabel
										control={<Checkbox checked={datakan} onChange={handleChangeWord} name="datakan" />}
										label="Datakan/ Filekan"
									/>
									<FormControlLabel
										control={<Checkbox checked={tindaklanjut} onChange={handleChangeWord} name="tindaklanjut" />}
										label="Tindaklanjuti"
									/>
									<FormControlLabel
										control={<Checkbox checked={wakili} onChange={handleChangeWord} name="wakili" />}
										label="Wakili"
									/>
									<FormControlLabel
										control={<Checkbox checked={lainnya} onChange={handleChangeWord} name="lainnya" />}
										label="Lainnya"
									/>
								</FormGroup>
							</FormControl>
							{lainnya && (
								<TextField
									fullWidth
									name="isi_disposisi"
									value={formState.values.isi_disposisi || ''}
									onChange={handleChange}
									placeholder="Lainnya"
									label="Isi Disposisi"
									multiline
									rows={4}
								/>
							)}
                        </Grid>
                    ):(
                        <div></div>
                    )}
                    {bagian.bagian.seq === 3 && (
                        <>
                            {staffMin !== null && !loadingStaffMin ? (
                                <Grid
                                    item
                                    lg={12}
                                >
                                    <TextField
                                        fullWidth
                                        name="kepada"
                                        value={formState.values.kepada || ''}
                                        onChange={handleChange}
                                        placeholder="Pilih Staff"
                                        label="Pilih Staff"
                                        select
                                    >
                                        {staffMin.map(item => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            ):(
                                <Skeleton variant="rect"></Skeleton>
                            )}
                        </>
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item>
                        <Button className={classes.button} onClick={handleSubmit(onSubmit)}>
                            <div className={classes.textWhite}>
                                Submit
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    </Fragment>
    
}

const mapStateToProps = state => ({
    memo: state.memo,
    staffMin: state.staffMin
})

export default connect(mapStateToProps, { dispositionMemo, getStaffMin })(Disposisi)
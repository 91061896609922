import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'
import fileDownload from 'js-file-download'

export const getInboxMail = (keyword) => async dispatch => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk?keyword=${keyword}`

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_INBOX_MAIL,
            payload: res.data.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_INBOX_MAIL,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
    
}

export const getInboxMailDone = (keyword, startDate, endDate) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/report/surat-masuk?keyword=${keyword}&start_date=${startDate}&end_date=${endDate}`
  
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
          dispatch({
              type: actions.GET_INBOX_MAIL_DONE,
              payload: res.data.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.GET_INBOX_MAIL_DONE,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const postInboxMail = (formData, file, mailDate, accDate, history) => async dispatch => {
    dispatch({
        type: actions.POST_INBOX_MAIL_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk`
    const myData = new FormData()
    myData.set('klasifikasi', formData.klasifikasi)
    myData.set('no_agenda', formData.no_agenda)
    myData.set('tanggal_surat', mailDate)
    myData.set('tanggal_terima', accDate)
    myData.set('status_type', formData.status_type)
    myData.set('perihal', formData.perihal)
    myData.set('file', file)
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'multipart/form-data', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.POST_INBOX_MAIL_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/surat-masuk')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.POST_INBOX_MAIL_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const updateInboxMail = (formData, file, mailDate, accDate, history, id) => async dispatch => {
    dispatch({
        type: actions.UPDATE_INBOX_MAIL_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/${id}`
    const myData = new FormData()
    myData.set('_method', 'put')
    myData.set('no_agenda', formData.no_agenda)
    // myData.set('no_surat', formData.no_surat)
    myData.set('tanggal_surat', mailDate)
    myData.set('tanggal_terima', accDate)
    myData.set('status_type', formData.status_type)
    // myData.set('sumber_surat', formData.sumber_surat)
    myData.set('perihal', formData.perihal)
    // myData.set('keterangan', formData.keterangan)
    if(file.length > 0){
        myData.set('file', file)
    }
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'multipart/form-data', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.UPDATE_INBOX_MAIL_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        history.push('/surat-masuk')
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.UPDATE_INBOX_MAIL_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const downloadBuktiTerima = (id, no_surat) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/${id}/tanda-terima/download`
    console.log(endpoint)
    dispatch({
        type: actions.DOWNLOAD_BUKTI_TERIMA_START,
    })
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              loading: true,
              headers: { 
                'Content-Type': 'application/pdf', 
                'Accept' : 'application/json', 
                'Authorization' : `Bearer ${sessionStorage.getItem('access_token')}`
              },
              responseType: 'blob'
          });
          fileDownload(res.data, `bukti_terima_${no_surat}.pdf`)
          dispatch({
              type: actions.DOWNLOAD_BUKTI_TERIMA_END,
              payload: res.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.DOWNLOAD_BUKTI_TERIMA_END,
              payload: error
          })
      }
      
}

export const downloadDetailSurat = (id, no_surat) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/${id}/detail-surat/download`
    console.log(endpoint)
    dispatch({
        type: actions.DOWNLOAD_DETAIL_SURAT_START,
    })
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              loading: true,
              headers: { 
                'Content-Type': 'application/pdf', 
                'Accept' : 'application/json', 
                'Authorization' : `Bearer ${sessionStorage.getItem('access_token')}`
              },
              responseType: 'blob'
          });
          fileDownload(res.data, `Detail Surat ${no_surat}.pdf`)
          dispatch({
              type: actions.DOWNLOAD_DETAIL_SURAT_END,
              payload: res.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.DOWNLOAD_DETAIL_SURAT_END,
              payload: error
          })
      }
      
}

export const getDispositionChoice = () => async dispatch => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/disposisi/users`

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_DISPOSITION_CHOICE,
            payload: res.data.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_DISPOSITION_CHOICE,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
    
}

export const dispositionInboxMail = (id, formData, isiDisposisi, history, setKeyword, noSurat) => async dispatch => {
    dispatch({
        type: actions.DISPOSITION_INBOX_MAIL_START,
    })

    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/${id}/disposisi`
    const myData = {
        kepada: formData.kepada,
        // catatan: formData.catatan,
        isi_disposisi: isiDisposisi
    }
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.DISPOSITION_INBOX_MAIL_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        dispatch(getInboxMail(''))
        history.push('/surat-masuk')
        // setKeyword(noSurat)
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.DISPOSITION_INBOX_MAIL_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}

export const getHistory = (id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/history/${id}`
  
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
          dispatch({
              type: actions.GET_HISTORY,
              payload: res.data.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.GET_HISTORY,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Login } from './layouts';

import {
  Dashboard as DashboardView,
  Profile as ProfileView,
  SuratMasuk as SuratMasukView,
  FormSuratMasuk as FormSuratMasukView,
  SuratKeluar as SuratKeluarView,
  FormSuratKeluar as FormSuratKeluarView,
  Memo as MemoView,
  HistorySuratMasuk as HistorySuratMasukView,
  HistoryMemo as HistoryMemoView,
  Report as ReportView,
  ReportHistory as ReportHistoryView,
  StaffMin as StaffMinView,
  CreateStaffMin as CreateStaffMinView,
  EditStaffMin as EditStaffMinView,
  MemoSuratKeluar as MemoSuratKeluarView,
  HistoryOutboxMail as HistoryOutboxMailView,
  HistoryOutboxMailMemo as HistoryOutboxMailMemoView,
  ReportOutboxMail as ReportOutboxMailView,
  ReportOutboxMailHistory as ReportOutboxMailHistoryView
} from './views';

const Routes = () => {
  
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        component={ProfileView}
        exact
        layout={MainLayout}
        path="/profile"
      />

      <RouteWithLayout
        component={SuratMasukView}
        exact
        layout={MainLayout}
        path="/surat-masuk"
      />

      <RouteWithLayout
        component={FormSuratMasukView}
        exact
        layout={MainLayout}
        path="/surat-masuk/form"
      />

      <RouteWithLayout
        component={HistorySuratMasukView}
        exact
        layout={MainLayout}
        path="/surat-masuk/:id/history"
      />

      <RouteWithLayout
        component={SuratKeluarView}
        exact
        layout={MainLayout}
        path="/surat-keluar"
      />

      <RouteWithLayout
        component={HistoryOutboxMailView}
        exact
        layout={MainLayout}
        path="/surat-keluar/:id/history"
      />

      <RouteWithLayout
        component={FormSuratKeluarView}
        exact
        layout={MainLayout}
        path="/surat-keluar/form"
      />

      <RouteWithLayout
        component={MemoView}
        exact
        layout={MainLayout}
        path="/memo"
      />

      <RouteWithLayout
        component={MemoSuratKeluarView}
        exact
        layout={MainLayout}
        path="/memo-surat-keluar"
      />

      <RouteWithLayout
        component={HistoryOutboxMailMemoView}
        exact
        layout={MainLayout}
        path="/memo-surat-keluar/:id/history"
      />

      <RouteWithLayout
        component={HistoryMemoView}
        exact
        layout={MainLayout}
        path="/memo/:id/history"
      />

      <RouteWithLayout
        component={ReportView}
        exact
        layout={MainLayout}
        path="/report"
      />

      <RouteWithLayout
        component={ReportHistoryView}
        exact
        layout={MainLayout}
        path="/report/:id/history"
      />

      <RouteWithLayout
        component={ReportOutboxMailView}
        exact
        layout={MainLayout}
        path="/report-surat-keluar"
      />

      <RouteWithLayout
        component={ReportOutboxMailHistoryView}
        exact
        layout={MainLayout}
        path="/report-surat-keluar/:id/history"
      />

      <RouteWithLayout
        component={StaffMinView}
        exact
        layout={MainLayout}
        path="/manajemen/staff-min"
      />

      <RouteWithLayout
        component={CreateStaffMinView}
        exact
        layout={MainLayout}
        path="/manajemen/staff-min/create"
      />

      <RouteWithLayout
        component={EditStaffMinView}
        exact
        layout={MainLayout}
        path="/manajemen/staff-min/edit/:id"
      />

      <Route
        component={Login}
        exact
        // layout={Login}
        path="/sign-in"
      />
      {/* <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      /> */}

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;

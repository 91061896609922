import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid } from '@material-ui/core'

import TableMemo from './TableMemo'

// redux
import { connect } from 'react-redux'
import { getMemoList } from '../../store/actions/memo'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Montserrat',
        color: '#000000'
    },
    button: {
        textTransform: 'none',
        backgroundColor: '#2285DF',
        color: '#FFFFFF',
        width: '100%',
        height: '40px',
        '&:hover': {
            backgroundColor: '#0277BD'
        },
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    textButton: {
        fontFamily: 'Montserrat',
        color: '#FFFFFF'
    }
}))

const Memo = props => {
    const classes = useStyles()
    const {
        getMemoList,
        memo: {
            memoList,
            loadingMemoList
        }
    } = props

    const [ keyword, setKeyword ] = useState('')
    const [page, setPage] = useState(0);

    const handleChangeSearch = event => {
        setKeyword(event.target.value)
        setPage(0)
    }

    useEffect(() => {
        getMemoList(keyword)
    }, [getMemoList, keyword])

    return(
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
                justify="space-between"
            >
                <Grid item>  
                    <Typography variant="h4" className={classes.title}>Memo Surat Masuk</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
            >
                <Grid item lg={12}>
                    {!loadingMemoList && memoList !== null ? (
                        <TableMemo 
                            memoList={memoList} 
                            setKeyword={setKeyword}
                            page={page}
                            setPage={setPage}
                            handleChangeSearch={handleChangeSearch}
                        />
                    ):(
                        <Skeleton variant="rect" height={300}></Skeleton>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => ({
    memo : state.memo
})

export default connect(mapStateToProps, { getMemoList })(Memo)
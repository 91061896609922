import * as actions from './actionTypes'
import axios from 'axios'
import { setAlert } from './alert'
import fileDownload from 'js-file-download'

export const getMemoList = (keyword) => async dispatch => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/disposisi?keyword=${keyword}`

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: actions.GET_MEMO,
            payload: res.data.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: actions.GET_MEMO,
            payload: error
        })
    }
    
}

export const downloadDocumentDisposition = (id, no_surat) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/disposisi/${id}/download`
    dispatch({
        type: actions.DOWNLOAD_DOCUMENT_DISPOSITION_START,
    })
      try {
          const res = await axios({
              url: endpoint,
              method: "GET",
              loading: true,
              headers: { 
                'Content-Type': 'application/pdf', 
                'Accept' : 'application/json', 
                'Authorization' : `Bearer ${sessionStorage.getItem('access_token')}`
              },
              responseType: 'blob'
          });
          fileDownload(res.data, `dokumen disposisi ${no_surat}.pdf`)
          dispatch({
              type: actions.DOWNLOAD_DOCUMENT_DISPOSITION_SUCCESS,
              payload: res.data
          })
  
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.DOWNLOAD_DOCUMENT_DISPOSITION_END,
              payload: error
          })
      }
      
}

export const dispositionMemo = (id, formData, history, setKeyword, noSurat) => async dispatch => {
    dispatch({
        type: actions.DISPOSITION_MEMO_START,
    })

    const endpoint = `${process.env.REACT_APP_BASE_URL}api/surat-masuk/${id}/disposisi`
    const myData = {
        kepada: formData.kepada,
        catatan: formData.catatan,
        isi_disposisi: formData.isi_disposisi 
    }
  
      try {
          const res = await axios({
              url: endpoint,
              method: "POST",
              data: myData,
              headers: { 
                'Content-Type': 'application/json', 
                'Accept' : 'application/json', 
                'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
              }
          });
        dispatch({
            type: actions.DISPOSITION_MEMO_SUCCESS,
            payload: res.data
        })
        dispatch(setAlert(res.data.message, "success"))
        // setKeyword(noSurat)
        dispatch(getMemoList(''))
        history.push('/memo')
        
      } catch (error) {
          dispatch(setAlert("Something went wrong", "error"))
          console.log(error)
          dispatch({
              type: actions.DISPOSITION_MEMO_FAIL,
              payload: error
          })
          // dispatch({
          //     payload: { msg: error.response.statusText, status: error.response.status },
          //     type: STAGE_ERROR
          // })
      }
      
}